:root {
  --toastify-toast-background: #ffffff;
  --toastify-toast-border: #000000;
  --toastify-color-error: #e74c3c;
  --toastify-color-success: #07bc0c;
}

// customize Toastify as indicated in docs
.Toastify {
  &__toast {
    min-height: 0;
    padding: 16px 24px;
    border: 1px solid var(--toastify-toast-border);
    background-color: var(--toastify-toast-background);
    &--success {
      border-color: var(--toastify-color-success);
    }

    &--error {
      border-color: var(--toastify-color-error);
    }
  }

  &__toast-container {
    &--top-center {
      left: calc(50% - 225px);
      top: 70px;
      transform: translate(0);
    }
  }

  &__toast-body {
    font-family: var(--font-family-regular);
    line-height: 16px;
    padding: 0;
  }

  &__close-button {
    @apply self-center;
  }

  // custom classes
  &__icon-container {
    @apply flex items-center;
  }

  .toastify__icon {
    margin-right: 17px;
  }
  @media (min-width: 900px){
    &__toast-container {
      width: auto;
    }
  }
}
